import React from "react";

const Logo = props => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 51 39"
    {...props}
  >
    <g fill="#DEDEDE">
      <path d="M25.9 33.55v5.01h24.4v-5.01H25.9zM50.53 0h-6.19L38.1 10.25 31.85 0h-6.18l9.34 15.33-9.34 15.33h6.18l.09-.14 6.16-10.11 6.24 10.25h6.19l-9.34-15.33L50.53 0zM0 0h21.23v5.5L8.59 38.56H2.63L15.24 5.5h-9.9v5.59H0V0z"></path>
    </g>
  </svg>
);

export default Logo;