import PropTypes from 'prop-types'
import React from 'react'

import Logo from './svgs/logo'
import './header.scss'

const Header = ({ siteTitle }) => (
  <header id="top">
    <div className="wrapper">
      <h1 style={{ margin: 0 }}>
        <Logo width="39" />
      </h1>

      <nav>
        <ol>
          <li><a href="#top">About</a></li>
          <li><a href="#roster">Roster</a></li>
          <li><a href="#brands">Brands</a></li>
          <li><a href="#contact">Contact</a></li>
        </ol>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
